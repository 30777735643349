import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";

import ScrollToTop from "./ScrollToTop";
import { useTranslation } from "react-i18next";
import Locations from "./pages/Locations/Locations";
import BusinessAndCareerPage from "./pages/BusinessAndCareerPage/BusinessAndCareerPage";
import ContactPage from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound/NotFound";

const AppRouter = () => {
  const { t, ready } = useTranslation();

  if (!ready) {
    return <div>Loading...</div>;
  }
  return (
    <ScrollToTop>
      <Routes>
        <Route path={t("nav.home.url")} element={<Home />} />
        <Route path={t("nav.location.url")} element={<Locations />} />
        <Route
          path={t("nav.workAndCareer.url")}
          element={<BusinessAndCareerPage />}
        />
        <Route path={t("nav.contact.url")} element={<ContactPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ScrollToTop>
  );
};

export default AppRouter;
