import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";
import { Col } from "react-bootstrap";

export const StyledContainer = styled.div`
  background: center center no-repeat;
  height: 700px;
  background-size: cover;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledContent = styled.div`
  padding: 30px 20px;

  ${breakpoints.up("xxl")} {
    width: 70%;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: end;
  align-items: center;

  ${breakpoints.up("md")} {
    text-align: right;
  }
`;

export const StyledEyebrow = styled.h3`
  color: ${(props) => props.theme.accent};
`;

export const StyledImg = styled.img`
  width: 100%;
`;
