import styled, { css } from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledContainer = styled.div`
  padding: 150px 20px;

  ${(props) =>
    props.$variant === "smallPadding" &&
    css`
      padding: 60px 20px 150px;

      ${breakpoints.down("md")} {
        padding: 30px 20px;
      }
    `}

  ${breakpoints.down("md")} {
    padding: 30px 20px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledMailLink = styled.a`
  color: rgb(0, 99, 228);
  font-size: 1.3rem;
  text-decoration: none;
  margin-top: 1em;
  display: inline-block;
  font-weight: bold;
  padding: 0.5em;
  margin-left: -0.5em;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  &:before,
  &:after {
    position: absolute;
    content: "";
    border-bottom: 3px solid rgb(0, 99, 228);
    border-radius: 1em;
    bottom: 0.3em;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:before {
    width: 1em;
    transform-origin: left;
  }

  &:after {
    width: 82%;
    left: 1em;
    transform: translateX(110%);
  }

  &:hover:before {
    transform: scaleX(0.3);
  }

  &:hover:after {
    transform: translateX(0);
  }
`;

export const StyledImgContainer = styled.div`
  overflow: hidden;
  border-radius: 40px;

  img {
    vertical-align: unset;
    float: inline-end;
    transform: translateX(100px);

    ${breakpoints.down("md")} {
      height: 400px;
      transform: translateX(10px);
    }
  }
`;

export const StyledCOntentContainer = styled.div`
  padding-right: 60px;

  ${breakpoints.down("md")} {
    padding-right: unset;
  }
`;
