import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.antiFlashWhite};
  margin-top: 40px;
`;

export const StyledFooterContent = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
