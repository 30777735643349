import React from "react";
import { StyledFooterContainer, StyledFooterContent } from "./Footer.styled";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <StyledFooterContainer>
      <Container>
        <Row>
          <Col>
            <StyledFooterContent>© {year} Family Pharm</StyledFooterContent>
          </Col>
        </Row>
      </Container>
    </StyledFooterContainer>
  );
};

export default Footer;
