import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/logo/logo.svg";
import {
  StyledLi,
  StyledLogo,
  StyledLanguageSelectorContainer,
} from "./Nav.styled";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { navigation as getNavLinks } from "./NavLinks";

const Nav = () => {
  const { t } = useTranslation();
  const navigation = getNavLinks(t);
  const [isOpen, setIsOpen] = useState(false);
  const openNav = () => setIsOpen(true);
  const closeNav = () => setIsOpen(false);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand me-0" href={t("nav.home.url")}>
          <StyledLogo src={logo} alt="family pharm logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={openNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse justify-content-end navbar-collapse ${
            isOpen ? "show" : ""
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 navbar-nav--border-bottom align-items-start align-items-lg-center">
            {navigation.length &&
              navigation.map(({ label, url }, index) => (
                <StyledLi key={index}>
                  <NavLink
                    index={`${index} - ${label}`}
                    className="nav-link"
                    to={url}
                    activeclassname="active"
                    onClick={closeNav}
                  >
                    {label}
                  </NavLink>
                </StyledLi>
              ))}
            <StyledLanguageSelectorContainer>
              <LanguageSelector closeNav={closeNav} />
            </StyledLanguageSelectorContainer>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
