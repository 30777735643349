import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledContainer = styled.div`
  padding: 150px 20px;

  ${breakpoints.down("md")} {
    padding: 30px 20px;
  }
`;

export const StyledImgContainer = styled.div`
  overflow: hidden;
  border-radius: 40px;
  margin-bottom: 40px;
`;

export const StyledParahraph = styled.p`
  margin-bottom: 30px;
`;
