import { Container } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";
import Info from "../../components/Info/Info";
import LocationPharmacy from "../../components/LocationPharmacy/LocationPharmacy";

const Home = () => {
  return (
    <>
      <Container fluid>
        <Hero />
        <Info />
      </Container>
      <Container>
        <LocationPharmacy homepageLocationShortDesc limit={4} locationButton />
      </Container>
    </>
  );
};

export default Home;
