import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledNav = styled.nav`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled.img`
  width: 400px;
  height: 100px;

  ${breakpoints.down("xl")} {
    width: 340px;
    height: 100px;
  }

  ${breakpoints.down("md")} {
    width: 200px;
    height: 50px;
  }
`;

export const StyledLi = styled.li`
  text-transform: uppercase;
`;

export const StyledLanguageSelectorContainer = styled.div`
  position: relative;
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;

  ${breakpoints.down("lg")} {
    margin: 15px 0;
    justify-content: start;
  }

  ul {
    position: absolute;
    padding-left: 0;
  }
`;

export const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;

export const StyledNavButton = styled.button`
  border: none;
  background: none;
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }

  /* Example with a Font Awesome icon */
  .navbar-toggler-icon::before {
    content: "\f0c9"; /* This is the Unicode for the 'bars' icon */
    font-weight: 900;
    color: #fff; /* Change color as needed */
    font-size: 1.5em; /* Adjust size as needed */
  }
`;
