import styled, { css, keyframes } from "styled-components";
import { breakpoints } from "../../themes/commonTheme";
import { Col, Row } from "react-bootstrap";

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

export const StyledContainer = styled.div`
  padding: 150px 20px;

  ${(props) =>
    props.$variant === "smallPadding" &&
    css`
      padding: 60px 20px 150px;

      ${breakpoints.down("md")} {
        padding: 30px 20px;
      }
    `}

  ${breakpoints.down("md")} {
    padding: 30px 20px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 18px;
  margin: 0;
  padding-right: 100px;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;

    ${breakpoints.down("md")} {
      margin-bottom: 40px;
    }
  }

  ${breakpoints.down("xl")} {
    padding-right: 0;
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 30px;

  ${breakpoints.down("md")} {
    padding: 10px;
  }
`;

export const StyledContentContainer = styled.div`
  position: relative;
`;

export const StyledIconContainer = styled.div`
  position: relative;
`;

export const StyledIconLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  svg {
    width: 4em;
    height: 4em;

    ${breakpoints.down("md")} {
      width: 3em;
      height: 3em;
    }
  }
`;

export const StyledCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px;
  background-color: ${(props) => props.theme.background};
  border: 2px solid ${(props) => props.theme.primary};
  width: 100%;
  min-height: 350px;
  border-radius: 40px;
  overflow: hidden;
  text-decoration: none;
  color: ${(props) => props.theme.primary};
  /* transition: transform 0.2s ease-in-out; */

  &:hover {
    border: 2px solid ${(props) => props.theme.accent};
    ${StyledIconContainer} {
      animation: ${pulse} 1s infinite;
      color: ${(props) => props.theme.accent};
    }
  }

  ${breakpoints.down("md")} {
    border-radius: 30px;
    padding: 20px;
    min-height: max-content;
    flex-direction: row;
    justify-content: unset;
    gap: 20px;
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 5px 15px;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  border: 1px solid ${(props) => props.theme.primary};
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right-color: ${(props) => props.theme.background};

  &:focus {
    outline: 0;
  }
`;

export const StyledImgContainer = styled.div`
  overflow: hidden;
  border-radius: 40px;

  img {
    vertical-align: unset;
    float: inline-end;

    ${breakpoints.down("md")} {
      height: 400px;
    }
  }
`;

export const StyledButtonReset = styled.button`
  position: absolute;
  top: 8px;
  left: -55px;
  background-color: ${(props) => props.theme.background};
  border: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

export const StyledButtonSearch = styled.button`
  box-sizing: border-box;
  height: 50px;
  padding: 0 20px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  border: 1px solid ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.background};
  border-left-color: ${(props) => props.theme.background};

  &:hover {
    background-color: ${(props) => props.theme.accent};
    border: 1px solid ${(props) => props.theme.primary};
    border-left-color: 1px solid ${(props) => props.theme.accent};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.primary};
    background-color: ${(props) => props.theme.primary};
  }

  &:disabled {
    background-color: ${(props) => props.theme.antiFlashWhite};
    color: ${(props) => props.theme.charcoal};
    cursor: not-allowed;
    border: 1px solid ${(props) => props.theme.primary};

    &:hover {
      background-color: ${(props) => props.theme.antiFlashWhite};
      color: ${(props) => props.theme.charcoal};
      border-left-color: 1px solid ${(props) => props.theme.antiFlashWhite};
    }
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 45px;
`;

export const StyledInfoText = styled.p`
  margin: 40px 0;
  background-color: ${(props) => props.theme.antiFlashWhite};
  color: ${(props) => props.theme.primary};
  padding: 30px;
  border-radius: 24px;
  font-size: 20px;
`;

export const StyledButtonContainer = styled.div`
  position: relative;
`;
