import React from "react";
import { StyledButton } from "./CustomBtn.styled";

const CustomBtn = ({ label, onClick }) => (
  <StyledButton label={label} onClick={onClick}>
    {label}
  </StyledButton>
);

export default CustomBtn;
