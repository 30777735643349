export const navigation = (t) => [
  {
    label: t("nav.home.label"),
    url: t("nav.home.url"),
  },
  {
    label: t("nav.location.label"),
    url: t("nav.location.url"),
  },
  {
    label: t("nav.workAndCareer.label"),
    url: t("nav.workAndCareer.url"),
  },
  {
    label: t("nav.contact.label"),
    url: t("nav.contact.url"),
  },
];
