import React, { useState, useEffect } from "react";
import i18n from "../../i18n";
import { StyledLi, StyledUl } from "./LanguageSelector.styled";
import { useNavigate, useLocation } from "react-router-dom";

const LanguageSelector = ({ closeNav }) => {
  const languages = ["sr", "en"];
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang")
  );
  const [isOpen, setIsOpen] = useState(false);

  const [rerender, setRerender] = useState(0); // Add this line

  const navigate = useNavigate();
  const location = useLocation();

  const chooseLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      setSelectedLanguage(lang);
      localStorage.setItem("lang", lang);
      const currentPath = location.pathname;
      const routes = i18n.getResourceBundle(lang, "routes");

      // Check if routes is not null or undefined
      if (routes) {
        const currentRouteKey = Object.keys(routes).find(
          (key) => routes[key] === currentPath
        );
        const newRoute = routes[currentRouteKey];
        if (newRoute !== currentPath) {
          navigate(newRoute, { replace: true });
        }
      }
      setRerender(rerender + 1); // Force a re-render
      closeNav();
    });
  };

  useEffect(() => {
    if (selectedLanguage) {
      const currentPath = location.pathname;
      const newPath = currentPath.replace(
        /^\[a-z]{2}\//,
        `${selectedLanguage}/`
      );
      if (newPath !== currentPath) {
        navigate(newPath, { replace: true });
      }
    }
  }, [selectedLanguage, location.pathname, navigate, rerender]); // Add rerender to the dependency array

  return (
    <StyledUl
      className={`${isOpen ? "open" : ""}`}
      onClick={() => setIsOpen(!isOpen)}
    >
      {languages.map((lang) => (
        <StyledLi
          key={lang}
          className={selectedLanguage === lang ? "activeLang" : ""}
          onClick={() => chooseLanguage(lang)}
        >
          {lang}
        </StyledLi>
      ))}
    </StyledUl>
  );
};

export default LanguageSelector;
