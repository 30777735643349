import { Container } from "react-bootstrap";
import LocationPharmacy from "../../components/LocationPharmacy/LocationPharmacy";

const Locations = () => {
  return (
    <>
      <Container>
        <LocationPharmacy
          locationPageDesc
          $variant="smallPadding"
          enableSearch
          locationPageImage
          md="7"
        />
      </Container>
    </>
  );
};

export default Locations;
