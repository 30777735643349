import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GoLocation, GoXCircle } from "react-icons/go";

import {
  StyledCardWrapper,
  StyledCol,
  StyledContainer,
  StyledContentContainer,
  StyledIconContainer,
  StyledParagraph,
  StyledInput,
  StyledIconLink,
  StyledImgContainer,
  StyledButtonSearch,
  StyledButtonReset,
  StyledInputContainer,
  StyledRow,
  StyledInfoText,
  StyledButtonContainer,
} from "./LocationPharmacy.styled";
import CustomBtn from "../CustomBtn/CustomBtn";

import location from "../../assets/location/location.webp";
import LinkLabel from "../LinkLabel/LinkLabel";

import { mapLocations as getMapLocations } from "./mapLocations";

const LocationPharmacy = ({
  limit,
  locationButton,
  $variant,
  enableSearch,
  homepageLocationShortDesc,
  locationPageDesc,
  locationPageImage,
  md,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mapLocations = getMapLocations(t);

  const [searchTerm, setSearchTerm] = useState("");
  const [finalSearchTerm, setFinalSearchTerm] = useState("");
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;

    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

    if (!specialCharRegex.test(inputValue)) {
      setSearchTerm(inputValue);
      if (inputValue.length < 3) {
        setIsSearchClicked(false);
      }
    }
  };

  const handleSearchClick = () => {
    setFinalSearchTerm(searchTerm);
    setIsSearchClicked(true);
  };

  const handleResetClick = () => {
    setSearchTerm("");
    setFinalSearchTerm("");
    setIsSearchClicked(false);
  };

  let filteredLocations = mapLocations;

  if (enableSearch) {
    filteredLocations = mapLocations.filter(
      (location) =>
        location.title.toLowerCase().includes(finalSearchTerm.toLowerCase()) ||
        location.city.toLowerCase().includes(finalSearchTerm.toLowerCase()) ||
        location.address.toLowerCase().includes(finalSearchTerm.toLowerCase())
    );
  }

  const locationsToShow = limit
    ? filteredLocations.slice(0, limit)
    : filteredLocations;

  const handleClick = () => {
    navigate(t("nav.location.url"));
  };

  return (
    <StyledContainer $variant={$variant}>
      <StyledRow>
        <Col md={md} className="pe-3 pe-md-5">
          <h2>{t("locationPharmacy.title")}</h2>
          {homepageLocationShortDesc && (
            <StyledParagraph>{t("locationPharmacy.desc")}</StyledParagraph>
          )}
          {locationPageDesc && (
            <>
              <StyledParagraph>
                {t("locationPharmacy.page.desc")}
              </StyledParagraph>
              <StyledParagraph>
                {t("locationPharmacy.page.desc2")}
              </StyledParagraph>
            </>
          )}
        </Col>
        {locationPageImage && (
          <Col md={5}>
            <StyledImgContainer>
              <img src={location} alt={t("locationPharmacy.title")} />
            </StyledImgContainer>
          </Col>
        )}
      </StyledRow>
      {enableSearch && (
        <Row>
          <Col>
            <StyledInputContainer>
              <StyledInput
                type="text"
                placeholder={t("locationPharmacy.page.searchPlaceholder")}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <StyledButtonContainer>
                <StyledButtonSearch
                  onClick={handleSearchClick}
                  disabled={searchTerm.length < 3}
                >
                  {t("locationPharmacy.search")}
                </StyledButtonSearch>
                {isSearchClicked && (
                  <StyledButtonReset onClick={handleResetClick}>
                    <GoXCircle />
                  </StyledButtonReset>
                )}
              </StyledButtonContainer>
            </StyledInputContainer>
          </Col>
        </Row>
      )}
      {enableSearch && filteredLocations.length === 0 && (
        <StyledInfoText>
          {t("locationPharmacy.noResults", { searchTerm: finalSearchTerm })}
        </StyledInfoText>
      )}
      {(filteredLocations.length !== 0 || finalSearchTerm === "") && (
        <Row className="mt-5 locations">
          {locationsToShow.length &&
            locationsToShow.map(({ title, address, city, phone, url }) => (
              <Col md={6} xl={4} xxl={3} key={title} className="mb-4">
                <StyledCardWrapper $index={`${title}`}>
                  <StyledIconContainer>
                    <StyledIconLink href={url} target="_blank" rel="noreferrer">
                      <GoLocation />
                    </StyledIconLink>
                  </StyledIconContainer>

                  <StyledContentContainer>
                    <h3>{title}</h3>
                    <p>{address}</p>
                    <p className="mb-5">{city}</p>
                    <LinkLabel href={`tel:${phone}`} label={phone} />
                  </StyledContentContainer>
                </StyledCardWrapper>
              </Col>
            ))}
        </Row>
      )}
      {locationButton && (
        <Row>
          <StyledCol xs={12}>
            <CustomBtn
              label={t("locationPharmacy.btnLabel")}
              onClick={handleClick}
            />
          </StyledCol>
        </Row>
      )}
    </StyledContainer>
  );
};

export default LocationPharmacy;
