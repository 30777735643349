import AppRouter from "./Router";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container } from "react-bootstrap";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import { StyledContainer, StyledRouterContainer } from "./App.styled";

const App = () => {
  return (
    <StyledContainer fluid className="p-0">
      <Container>
        <Nav />
      </Container>
      <StyledRouterContainer>
        <AppRouter />
      </StyledRouterContainer>
      <Footer />
    </StyledContainer>
  );
};

export default App;
