import { Container } from "react-bootstrap";
import Contact from "../../components/Contact/Contact";

const ContactPage = () => {
  return (
    <>
      <Container>
        <Contact $variant="smallPadding" />
      </Container>
    </>
  );
};

export default ContactPage;
