import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  StyledContainer,
  StyledParagraph,
  StyledImgContainer,
  StyledCOntentContainer,
} from "./BusinessAndCareer.styled";

import career from "../../assets/career/career.webp";
import LinkLabel from "../LinkLabel/LinkLabel";

const BusinessAndCareer = ({ $variant }) => {
  const { t } = useTranslation();
  const mailLink = `mailto:${t("business.career.mail")}`;
  const mailLinkLabel = t("business.career.mail");

  return (
    <StyledContainer $variant={$variant}>
      <Row>
        <Col xs={12} lg={6} className="mb-lg-0 mb-5">
          <StyledCOntentContainer>
            <h2>{t("business.career.title")}</h2>
            <StyledParagraph>{t("business.career.desc.one")}</StyledParagraph>
            <StyledParagraph>{t("business.career.desc.two")}</StyledParagraph>
            <StyledParagraph>{t("business.career.desc.three")}</StyledParagraph>
            <LinkLabel href={mailLink} label={mailLinkLabel} />
          </StyledCOntentContainer>
        </Col>
        <Col xs={12} lg={6} className="mb-lg-0 mb-5">
          <StyledImgContainer>
            <img src={career} alt={t("business.career.title")} />
          </StyledImgContainer>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default BusinessAndCareer;
