import { Container } from "react-bootstrap";
import BusinessAndCareer from "../../components/BusinessAndCareer/BusinessAndCareer";

const BusinessAndCareerPage = () => {
  return (
    <>
      <Container>
        <BusinessAndCareer $variant="smallPadding" />
      </Container>
    </>
  );
};

export default BusinessAndCareerPage;
