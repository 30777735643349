import React from "react";
import { Row } from "react-bootstrap";
import hero from "../../assets/home-page/hero.webp";
import {
  StyledCol,
  StyledContent,
  StyledContentContainer,
  StyledEyebrow,
  StyledImg,
} from "./Hero.styled";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <Row className="backgroundHero">
      <StyledCol xs={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }}>
        <StyledContentContainer>
          <StyledContent>
            <StyledEyebrow>{t("hero.subtitle")}</StyledEyebrow>
            <h1>{t("hero.title")}</h1>
          </StyledContent>
        </StyledContentContainer>
      </StyledCol>
      <StyledCol
        xs={{ span: 12, order: 1 }}
        md={{ span: 5, order: 1 }}
        className="pe-0"
      >
        <StyledImg src={hero} alt="hero" />
      </StyledCol>
    </Row>
  );
};

export default Hero;
