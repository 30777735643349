import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";
import { Col, Row } from "react-bootstrap";

export const StyledWrapperBg = styled.div`
  background-color: ${(props) => props.theme.antiFlashWhite};
`;

export const StyledInfoContainer = styled(Row)`
  padding: 150px 20px;

  ${breakpoints.down("md")} {
    padding: 50px 10px;
  }
`;

export const StyledCol = styled(Col)`
  flex: 50%;
  margin-bottom: 20px;

  ${breakpoints.down("md")} {
    flex: 100%;
  }
`;

export const StyledDiv = styled.div`
  min-height: 270px;
  border-radius: 40px;
  padding: 40px;
  background-color: ${(props) => props.$backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;

  ${breakpoints.up("lg")} {
    min-height: 346px;
  }

  ${breakpoints.up("xl")} {
    min-height: 330px;
  }
`;

export const StyledParagraph = styled.p`
  margin-right: 70px;
  margin-bottom: 30px;
  font-size: 18px;

  ${breakpoints.down("lg")} {
    margin-right: 0;
  }
`;

export const StyledContentWrapper = styled.div`
  position: relative;
`;
