import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./globalStyles";
import themes from "./themes/commonTheme";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Suspense } from "react";

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    {/* remove /apoteka when deploying */}
    <ThemeProvider theme={themes}>
      <GlobalStyle />
      <Suspense fallback={<div>Loading....</div>}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>
);
