import styled from "styled-components";
// import { breakpoints } from "../../themes/commonTheme";

export const StyledUl = styled.ul`
  display: inline-flex;
  flex-direction: column;
  text-transform: uppercase;
  height: 50px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 15px;
  color: ${(props) => props.theme.primary};

  &.open {
    height: auto;
  }
`;

export const StyledLi = styled.li`
  text-align: center;
  text-transform: uppercase;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.antiFlashWhite};
  color: ${(props) => props.theme.primary};

  order: 2;
  padding: 10px;
  min-height: 50px;

  &.activeLang {
    order: 1;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.primary};
    color: ${(props) => props.theme.antiFlashWhite};
  }
`;
