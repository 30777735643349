import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  StyledCol,
  StyledContentWrapper,
  StyledDiv,
  StyledInfoContainer,
  StyledParagraph,
  StyledWrapperBg,
} from "./Info.styled";
import { useTranslation } from "react-i18next";

import { BsCalendarWeek } from "react-icons/bs";
import { GiMedicines, GiMedicalThermometer } from "react-icons/gi";
import { PiClockClockwiseFill } from "react-icons/pi";

const Info = () => {
  const { t } = useTranslation();

  const infoData = [
    {
      icon: BsCalendarWeek,
      title: t("info.card.one.title"),
      description: t("info.card.one.desc"),
      backgroundcolor: "lavanderPink",
    },
    {
      icon: GiMedicines,
      title: t("info.card.two.title"),
      description: t("info.card.two.desc"),
      backgroundcolor: "uraniumBlue",
    },
    {
      icon: PiClockClockwiseFill,
      title: t("info.card.three.title"),
      description: t("info.card.three.desc"),
      backgroundcolor: "champagnePink",
    },
    {
      icon: GiMedicalThermometer,
      title: t("info.card.four.title"),
      description: t("info.card.four.desc"),
      backgroundcolor: "neutral",
    },
  ];

  return (
    <Row>
      <StyledWrapperBg>
        <Container>
          <StyledInfoContainer>
            <Col
              md={12}
              lg={5}
              className="d-flex flex-column justify-content-center"
            >
              <StyledContentWrapper>
                <h2>{t("info.title")}</h2>
                <StyledParagraph>{t("info.desc.partOne")}</StyledParagraph>
                <StyledParagraph>{t("info.desc.partTwo")}</StyledParagraph>
              </StyledContentWrapper>
            </Col>
            <Col md={12} lg={7}>
              <Row>
                {infoData.length &&
                  infoData.map((component, index) => (
                    <StyledCol key={index} md={6}>
                      <StyledDiv
                        $backgroundColor={(props) =>
                          props.theme[component.backgroundcolor]
                        }
                      >
                        <component.icon size={"4em"} />
                        <h3>{t(component.title)}</h3>
                        <p>{t(component.description)}</p>
                      </StyledDiv>
                    </StyledCol>
                  ))}
              </Row>
            </Col>
          </StyledInfoContainer>
        </Container>
      </StyledWrapperBg>
    </Row>
  );
};

export default Info;
