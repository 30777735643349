import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationSR from "./locales/sr/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  sr: {
    translation: translationSR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang"),
  debug: false,
  fallbackLng: "sr",
  interpolation: {
    escapeValue: false,
  },
  ns: "translation",
  defaultNS: "translation",
});

export default i18n;
