import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledLinkLabel = styled.a`
  color: ${(props) => props.theme.accent};
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  width: max-content;
  font-weight: bold;
  padding: 0.5em;
  margin-left: -0.5em;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  overflow-x: hidden;

  &:before,
  &:after {
    position: absolute;
    content: "";
    border-bottom: 3px solid ${(props) => props.theme.accent};
    border-radius: 1em;
    bottom: 0.3em;
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:before {
    width: 1em;
    transform-origin: left;
  }

  &:after {
    width: 100%;
    left: 1em;
    transform: translateX(110%);
  }

  &:hover:before {
    transform: scaleX(0.3);
  }

  &:hover:after {
    transform: translateX(0);
  }

  ${breakpoints.down("md")} {
    width: unset;
  }
`;
