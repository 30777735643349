import React from "react";
import { StyledLinkLabel } from "./LinkLabel.styled";

const LinkLabel = ({ label, className, link, ...otherProps }) => {
  return (
    <StyledLinkLabel label={label} href={link} {...otherProps}>
      {label}
    </StyledLinkLabel>
  );
};

export default LinkLabel;
