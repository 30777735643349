import styled from "styled-components";
import { breakpoints } from "../../themes/commonTheme";

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow: none;
  font-size: 18px;
  line-height: 1.35;
  padding: 15px 30px;
  cursor: pointer;
  border-radius: 50px;
  border: 0;
  transform: color 0.3s ease-in-out;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.background};

  &:hover {
    background-color: ${(props) => props.theme.accent};
  }

  ${breakpoints.down("md")} {
    width: 100%;
  }
`;
