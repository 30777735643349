import { Col, Container, Row } from "react-bootstrap";
import noPage from "../../assets/not-found/404.webp";
import {
  StyledContainer,
  StyledImgContainer,
  StyledParahraph,
} from "./NotFound.styled";
import { useTranslation } from "react-i18next";
import LinkLabel from "../../components/LinkLabel/LinkLabel";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <StyledContainer>
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Row>
                <Col xs={12} md={6}>
                  <StyledImgContainer>
                    <img className="img-fluid" src={noPage} alt="no page" />
                  </StyledImgContainer>
                </Col>
                <Col xs={12} md={6}>
                  <StyledParahraph>{t("notFoundText")}</StyledParahraph>
                  <LinkLabel
                    label={t("notFoundGoBackText")}
                    href={t("nav.home.url")}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </StyledContainer>
    </>
  );
};

export default NotFound;
