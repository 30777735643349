import { Container } from "react-bootstrap";
import styled from "styled-components";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledRouterContainer = styled.div`
  flex-grow: 1;
`;
